// mixins

@mixin clear {
  margin: 0;
  padding: 0;
}

@mixin clear-list {
  @include clear;
  text-indent: 0;
  list-style-type: none;
}