@import "./../../scss/main";

.info-panel {
  $avatarSize: 30px;

  &__avatar {
    width: $avatarSize;
    height: $avatarSize;
  }

  &__avatar-wrapper {
    cursor: pointer;
    margin: 30px;
    cursor: pointer;
  }

  &__avatar--clip {
    width: $avatarSize;
    height: $avatarSize;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  &__delimeter {
    margin: 0 10px 0 30px;
    height:80px;
    border-left: 1px solid $infoPanelDelimiterColor;
    @include xs-screen  {
      margin: 0 10px 0 0px;
    }
  }

  &__content {
    color:  $infoPanelContentColor;
    font-size: 12px;
    text-align: left;
    font-family: arial, sans-serif
  }


}
