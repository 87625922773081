@import "./../scss/main";

.page {
  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    overflow: hidden;
  }

  &__content {

  }

  &__footer {
    text-align: right;
    display: flex;
    align-items: flex-end;
    height: 100px;
  }
}

