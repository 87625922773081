//fonts
@mixin font_Roboto_thin {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

@mixin font_Roboto_light {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

@mixin font_Roboto_regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

@mixin font_Roboto_bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

@mixin font_OpenSans_regular {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

@mixin font_OpenSans_bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

@mixin font_FjallaOne_regular {
  font-family: 'Fjalla One', sans-serif;
}