@import "./../../scss/main";

@mixin quip-export-main-font {
  font-family: arial, sans-serif;
}

.quip-export {
  @include quip-export-main-font;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 60px 0 60px;

  @include xs-screen  {
    margin: 30px 30px 0 30px;
  }

  .progressor {
    align-self: center;
    width: 400px;

    &__spinner {

    }

    &__area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__message {
      color: $progressorMessageColor;
      @include font_FjallaOne_regular;
      font-size: 20px;
      height: 50px;
    }

    &__text {
      font-size: 18px;
      height: 50px;
    }

    &__value {
      font-size: 48px;
      color: $progressorValueColor;
      @include font_FjallaOne_regular;
      height: 50px;
    }

    @include lg-screen {
      width: 300px;
      &__message {
        margin-bottom: 20px;
        font-size: 18px;
      }

      &__text {
        margin-bottom: 20px;
        font-size: 16px;
      }

      &__value {
        font-size: 48px;
      }
    }

    @include xs-screen  {
      width: 80vw;
    }
  }

  .form-area {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 60px;
    align-items: center;

    .margin-btm {
      margin-bottom: 30px;
    }

    &__row {
      text-align: center;

      &--equal-input {
        width: 500px;

        @include lg-screen {
          width: 350px;
        }

        @include xs-screen  {
          width: 90vw;
        }
      }

      & b {
        font-weight: 700;
      }
    }

    &__input {
      @include quip-export-main-font;
      background-color: $inputBackgroundColor;
      border: 0;
      border-radius: 5px;
      padding: 5px;
      width: 500px;
      color: $inputTextColor;
      text-align: center;
      font-size: 16px;

      &:focus {
        outline: none;
      }

      @include lg-screen {
          width: 350px;
      }
      @include xs-screen  {
        width: 90vw;
      }
    }

    &__input::placeholder {
      color: $inputPlaceholderColor;
      @include xs-screen  {
        font-size: 12px;
      }
    }
  }

  .export-area {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-top: 3vh;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 30px;
    @include sm-screen  {
      flex-direction: column;
    }
  }

  .star {
    align-self: center;
    margin: 0px 0 20px 0;
    text-align: center;
    font-family: arial, sans-serif;
    font-size: 16px;
    color: $githubStarColor;
  }

  .header, .header a {
    font-family: arial, sans-serif;
    font-size: 20px;
    align-self: center;
    margin: 60px 0 60px 0;
    text-align: center;
  }

  .logo {
    font-size: 36px;
    @include font_FjallaOne_regular;
    position: relative;
    color: $logoColor1;

    &--shift {
      top: 6px;
      color: $logoColor2;
      position: relative;
    }
  }

  .btn-start {
    background-color: $startButtonBgColor !important;
    color: $startButtonColor !important;
  }

  .btn-tiny {
    padding: 5px !important;
    font-size: 14px !important;
    @include quip-export-main-font;
  }

  .options {
    display: flex;
    width: 100%;
    justify-content: space-around;
    &__item {

    }
  }
}
