@import "./../../scss/main";

.slide-panel {
    &__wrapper {
        overflow: hidden;
        width: 430px;
        height: 100px;
        position:absolute;
        right:0;
        @include xs-screen  {
            width: 400px;
        }
    }

    &__slide {
        position: absolute;
        right: -310px;
        width: 430px;
        height: 90px;
        transition: 1s ease-in-out;
        @include xs-screen  {
            width: 350px;
            right: -260px;
        }
    }

    &__slide--move {
        right: 0;
    }
}
