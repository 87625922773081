@mixin lg-screen() {
  @media only screen and (max-width: $lg-screen-max-width) {
    @content;
  }
}

@mixin sm-screen() {
  @media only screen and (max-width: $sm-screen-width) {
    @content;
  }
}

@mixin xs-screen() {
  @media only screen and (max-width: $xs-screen-width) {
    @content;
  }
}