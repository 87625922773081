@import "./scss/main";

html, body {
  @include clear;
  height: 100%;
  width: 100%;
  background: $pageBgColor1;
  //background: linear-gradient(0deg, $pageBgColor1 25%,  $pageBgColor2 100%);
  background: radial-gradient(circle, $pageBgColor1 25%,  $pageBgColor2 100%);
  color: $mainColor;
  //font-family: Arial;

  //font mixin
  font-size: 16px;
  font-family: arial, sans-serif;

  scroll-behavior: smooth;
}

pre {
  color: $mainColor;
}

a, a:link, a:visited {
  text-decoration: underline;
  color: $mainColor;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6, p {
  //clear mixin
  //@include clear;
}

ul, li {
  //clear mixin for lists
  //@include clear-list;
}

//Font-Awesome mixins und variables usage example
/*&:before {
  @include fa-icon;
  color: #333;
  content: $fa-var-globe;
  margin-right: 6px;
}*/


//*********** BOM-Example ***************
.block1 {

  &--modifikator2 {
    font-size: 12px;
  }



  background-color: gray;

  &__element1 {
    color: blue;

    &--modificator1 {
      font-weight: bold;
    }
  }

  &__element2 {
    color: red;
  }
}
//*********** BOM-Example ***************
