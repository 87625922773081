@import "./../../scss/main";

.accent-text {
    &__overlay {
        position: absolute;
        white-space: nowrap;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation-duration: 1.5s;
        animation-name: fadeAndScale;
        overflow: hidden;
        opacity: 0;
    }

    @keyframes fadeAndScale {
        from {
            opacity: 1;
        }
        to {
            font-size: 3em;
            opacity: 0;
        }
    }
}
